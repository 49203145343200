import React from "react";
import styles from "./WebsiteBox.module.css";

function WebsiteBox({ link }) {
  return (
    <div className={styles.websiteBox}>
      <a href={link} target="_blank" rel="noopener noreferrer">
        Link Ebay <span className={styles.arrow}>&rarr;</span>
      </a>
    </div>
  );
}

export default WebsiteBox;
