import React from "react";
import styles from "./TitleBox.module.css";

function TitleBox({title}) {
    
return (
    <div className={styles.title}>
            {title}
    </div>
);
}

export default TitleBox;
