import React from "react";
import styles from "./ListingGridActive.module.css";
import useDataWithFilters from "../../hooks/useDataWithFilters";
import ListingGrid from "../ListingGrid/ListingGrid";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

function ListingGridActive() {
  const apiUrl = `https://bbourse-f97c610fe272.herokuapp.com/book_listings/?sold=False&`;

  const { data: listingsData, loading } = useDataWithFilters(apiUrl);

  if (loading) {
    return (
      <div className={styles.listingsContainer}>
        <Skeleton count={5} height={150} />
      </div>
    );
  }

  const hasData = Array.isArray(listingsData) && listingsData.length > 0;

  return (
    console.log('a',listingsData),
    <div className={styles.listingsContainer}>
      {hasData ? (
        <ListingGrid listingData={listingsData}></ListingGrid>
      ) : (
        <div>No listings found.</div>
      )}
    </div>
  );
}

export default ListingGridActive;
