import styles from "./PageContainer.module.css";
import React from "react";
import TopBar from "../../page-components/TopBar/TopBar";
import Footer from "../../page-components/Footer/Footer";

function PageContainer({ children }) {
    return (
        <div className={styles.outerContainer}>
            <TopBar />
            <div className={styles.page}>
                {children}
            </div>
            <Footer />
        </div>
        
    );
}

export default PageContainer;
