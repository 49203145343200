// Home.jsx
import React from "react";
import PageContainer from "../../page-components/PageContainer/PageContainer";
import HomeHeader from "../../page-components/HomeHeader/HomeHeader";
import HomeGrid from "../../page-components/HomeGrid/HomeGrid";
function Home() {

  return (
    <div>
      <PageContainer>
        <HomeHeader />
        <HomeGrid />
      </PageContainer>
    </div>
  );
}

export default Home;
