import React from "react";
import styles from "./EditionGridItem.module.css";

function EditionGridItem({ data, onClick }) {
  return (
    <div className={styles.containerGridItem}>
    <div className={styles.gridItem} onClick={() => onClick(data.edition_id)}> 
      <img src={data.img_link} alt={data.title} className={styles.bookImage} />
      <div className={styles.bookInfo}>
        <div className={styles.bookTitle}>{data.title}</div>
        <div className={styles.bookSubtitle}>{data.subtitle}</div>
      </div>
    </div>
    </div>
  );
}

export default EditionGridItem;
