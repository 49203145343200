import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  edition_id: null,
  print_number: null,
  signed: null,
  condition:null,
};

const FilterContext = createContext();

export const useFilters = () => {
  return useContext(FilterContext);
};

const SET_EDITION_ID = 'SET_EDITION_ID';
const SET_PRINT_NUMBER = 'SET_PRINT_NUMBER';
const SET_SIGNED = 'SET_SIGNED';
const SET_CONDITION = 'SET_CONDITION';

const filterReducer = (state, action) => {
  switch (action.type) {
    case SET_EDITION_ID:
      return { ...state, edition_id: action.payload };
    case SET_PRINT_NUMBER:
      return { ...state, print_number: action.payload };
    case SET_SIGNED:
      return { ...state, signed: action.payload };
    case SET_CONDITION:
      return { ...state, condition: action.payload };
    default:
      return state;
  }
};

const FilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(filterReducer, initialState);

  return (
    <FilterContext.Provider value={{ state, dispatch }}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;