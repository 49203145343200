import React from 'react';
import styles from './HomeHeader.module.css';

const HomeHeader = () => {
    const scrollToSection = () => {
        const section = document.getElementById("targetSection");
        const yOffset = -80; 
        const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      
        window.scrollTo({ top: y, behavior: 'smooth' });
      };

    return (
        <div className={styles.container}>
            <div className={styles.subtitle}>WELCOME TO BBOURSE!</div>
            <div className={styles.title}>
                Our mission is to integrate AI in the world of rare books!
            </div>
            <div className={styles.description}>
                Track market trends, find current listings, and uncover the worth of your rare collection.
            </div>
            <button className={styles.button}  onClick={scrollToSection}>
                Discover Now
            </button>
        </div>
    );
};


export default HomeHeader;
