import React from "react";
import styles from "./PrintNumberBox.module.css";

function PrintNumberBox({printNumber}) {
  const printNumberDict = {
    'first': 'First Print',
    'other': 'Other/Unsure'}
  return (
    <div className={styles.printNumberBox}>
        {printNumberDict[printNumber]}
    </div>
  );
}

export default PrintNumberBox;
