import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns'; // Import the adapter
import useDataWithFilters from '../../hooks/useDataWithFilters';
import styles from './SalesChart.module.css';
import annotationPlugin from 'chartjs-plugin-annotation'; // Import the default export


Chart.register(annotationPlugin); 

const SalesChart = () => {
  const chartRef = useRef(null);
  const apiUrl = `https://bbourse-f97c610fe272.herokuapp.com/sales_estimates/?`;
  const { data: salesData, loading: loadingSales } = useDataWithFilters(apiUrl);

  useEffect(() => {
    // Early exit if data is not ready or loading is in progress
    if (loadingSales || !salesData || salesData.length === 0) {
      return;
    }
  
    const ctx = chartRef.current.getContext('2d');
  
    const labels = salesData.map(item => item.sale_date.substring(0, 10)); // Assuming sale_date is in YYYY-MM-DD format
    const dataPoints = salesData.map(item => item.price);
  
    // Clear previous chart instance if it exists
    if (window.myLineChart instanceof Chart) {
      window.myLineChart.destroy();
    }
  
    // Create a gradient for the fill
    const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
    gradient.addColorStop(0, 'rgba(30, 215, 92, 0.4)'); // Less transparent near the line
    gradient.addColorStop(1, 'rgba(30, 215, 92, 0.1)'); // More transparent near the bottom
  
    // Creating the chart
    window.myLineChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels, // This can be omitted for time axis, data points will use 'x' and 'y' properties
        datasets: [{
          label: 'Price',
          data: salesData.map(item => ({
            x: item.sale_date, // Ensure this is a date string or a Date object
            y: item.price
          })),
          fill: true,
          backgroundColor: gradient, // Use the gradient as the background color
          borderColor: '#11AF80',
          borderWidth: 2,
          tension: 0.2,
          pointRadius: 0, // Set a small point radius to make the dots visible
          pointBackgroundColor: '#637588', // Color of the dots
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day', // Adjust based on your data's granularity
              tooltipFormat: 'MMM d, yyyy', // Format for the tooltip
              displayFormats: {
                day: 'MMM-d' // Adjust according to your needs
              }
            },
            title: {
              display: false,
              text: 'Date'
            },
            grid: {
              display: false // Set to true if you want grid lines for the x-axis
            },
            ticks: {
              source: 'auto',
              autoSkip: true,
            }
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
            },
            border :{dash: [5, 4]},
            grid: {
              display: true,
              lineWidth: 1,
            },
          }
        },
        plugins: {
          legend: {
            display: false,
            position: 'top'
          },
          tooltip: {
            mode: 'index',
            intersect: false,
          },
        }
      }
    });
  }, [salesData, loadingSales]); // Add salesData and loadingSales as dependencies
  // Add salesData and loadingSales as dependencies

  return (
    <div className={styles.chartContainer}>
      {loadingSales ? (
        <p>Loading...</p>
      ) : (
        salesData && salesData.length > 0 ? (
          <canvas ref={chartRef} className={styles.canvasFullWidth}></canvas>
        ) : (
          <p>No Data Available</p>
        )
      )}
    </div>
  );
};

export default SalesChart;
