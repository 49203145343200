import React, { useState } from "react";
import useData from "../../hooks/useData";
import styles from "./HomeGrid.module.css";
import EditionGridItem from "../../bbourse-components/EditionGridItem/EditionGridItem";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useFilters } from "../../context/filterContext";
import SideMenu from "../../bbourse-components/SideMenu/SideMenu";

function HomeGrid() {
  const navigate = useNavigate();
  const { state, dispatch } = useFilters();
  const [selectedCategory, setSelectedCategory] = useState("all");

  const selectedCategoryDic = {
    all: "verified",
    popular: "popular",
    new: "new",
  };

  const apiUrl = `https://bbourse-f97c610fe272.herokuapp.com/${selectedCategoryDic[selectedCategory]}_editions/`;

  const { data: bookEditions, loading, error } = useData(apiUrl);

  const handleEditionClick = (editionId) => {
    dispatch({ type: "SET_EDITION_ID", payload: { editionId } });
    navigate(`/edition/${editionId}`);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const menuItems = [
    { label: "All", icon: "apps", category: "all" },
    { label: "Popular Edition", icon: "favorite", category: "popular" },
    { label: "New", icon: "fiber_new", category: "new" },
  ];

  return (
    <div className={styles.container} id="targetSection">
      <SideMenu
        items={menuItems}
        selectedCategory={selectedCategory}
        onCategoryChange={handleCategoryChange}
      />
      <div className={styles.content}>
        <div className={styles.gridTitle}>
          Browse <span>in</span>{" "}
          {selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)}
        </div>
        <div className={styles.gridContainer}>
          {loading && (
            <div className={styles.skeletonContainer}>
              {Array.from({ length: 12 }).map((_, index) => (
                <div key={index} className={styles.skeletonItem}>
                  <Skeleton height={200} width={150} />
                  <div className={styles.skeletonText}>
                    <Skeleton
                      width={250}
                      height={20}
                      style={{ marginBottom: "10px" }}
                    />
                    <Skeleton width={150} height={20} />
                    <Skeleton width={100} height={20} />
                  </div>
                </div>
              ))}
            </div>
          )}
          {error && <p>Error loading data!</p>}
          {bookEditions &&
            bookEditions.map((edition, index) => (
              <EditionGridItem
                key={index}
                data={edition}
                onClick={() => handleEditionClick(edition.edition_id)}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default HomeGrid;
