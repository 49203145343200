import React from 'react';
import { useFilters } from "../../context/filterContext";
import Filter from "../../bbourse-components/Filter/Filter";
import styles from "./FiltersListings.module.css";
const FiltersListings = () => {
  const { state, dispatch } = useFilters();
  const { edition_id, print_number, signed, condition } = state;

  const signedOptions = [
    { value: "", label: "All" },
    { value: "signed", label: "Signed" },
    { value: "unsigned", label: "Unsigned" },
  ];

  const printNumberOptions = [
    { value: "", label: "All" },
    { value: "first", label: "First Print" },
    { value: "other", label: "Other/Unsure" },
  ];

  const updateSignedFilter = (e) => {
    dispatch({ type: "SET_SIGNED", payload: e.target.value });
  };

  const updatePrintNumber = (e) => {
    dispatch({ type: "SET_PRINT_NUMBER", payload: e.target.value });
  };

  return (
    <div className={styles.filters}>
      <Filter
        label="Signed"
        value={signed}
        onChange={updateSignedFilter}
        options={signedOptions}
      />
      <Filter
        label="Print Number"
        value={print_number}
        onChange={updatePrintNumber}
        options={printNumberOptions}
      />
    </div>
  );
};

export default FiltersListings;
