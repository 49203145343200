import React from "react";
import styles from "./EditionHeader.module.css";
import Title from "../../bbourse-components/Title/Title";
import useEditionData from "../../hooks/useEditionData";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FiltersListings from "../FiltersListings/FiltersListings";
function EditionHeader() {
  const { editionID } = useParams();
  const { editionData, loading, error } = useEditionData(editionID);

  if (loading) {
    return (
      <div className={styles.header}>
        <div className={styles.imgContainer}>
          <Skeleton height={150} width={200} />
        </div>
        <div>
          <Skeleton height={40} width={300} />
          <Skeleton height={20} width={200} style={{ marginTop: 10 }} />
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error loading data.</div>;
  }

  return (
    <div className={styles.header}>
      <div className={styles.imgContainer}>
        <img className={styles.productImage} src={editionData.img_link} alt={editionData.title} />
      </div>
      <div>
            <div className={styles.titleContainer}>
      <div className={styles.mainTitle}>{editionData.title}</div>
      <div className={styles.subTitle}>{editionData.subtitle}</div>
    </div>
        <div className={styles.filters}>
        </div>
      </div>
    </div>
  );
}

export default EditionHeader;
