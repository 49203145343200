import React, { useState } from "react";
import styles from "./ImageCarousel.module.css";

function ImageCarousel({ images }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className={styles.containerImageCarousel}>
      <button onClick={handlePreviousImage} className={styles.arrowButton}>
        <span>&lt;</span>
      </button>
      <img
        src={images[currentImageIndex]}
        alt=""
        className={styles.listingImage}
      />
      <button onClick={handleNextImage} className={styles.arrowButton}>
        <span>&gt;</span>
      </button>
    </div>
  );
}

export default ImageCarousel;
