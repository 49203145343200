import React from "react";
import styles from "./Footer.module.css";

function Footer({  }) {

  return (
    <div className={styles.footer}>
        <div className={styles.leftSection}>Work in Progress: More to come ;) </div>
        <div className={styles.rightSection}>© 2024 BBourse</div>
    </div>
  );
}

export default Footer;
