import { useState, useEffect } from 'react';
import axios from 'axios';

function useEditionData(editionID) {
  const [editionData, setEditionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  console.log('t')
  const apiUrl = `https://bbourse-f97c610fe272.herokuapp.com/editions/${editionID}`;
  console.log(apiUrl)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        setEditionData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (editionID) {
      fetchData();
    }
  }, [editionID]);

  return { editionData, loading, error };
}

export default useEditionData;