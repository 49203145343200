import React from "react";
import styles from "./TopBar.module.css";

function TopBar({  }) {

  return (
    <div className={styles.topBar}>
         <a href="/" className={styles.linkImg}>
        <img src="/BBourse_Logo_WhiteBG.png" className={styles.logo}/>
        </a>
    </div>
  );
}

export default TopBar;
