import logo from "./logo.svg";
import "./App.css";
import FilterProvider from "./context/filterContext";
import Home from "./pages/Home/Home";
import SingleEdition from "./pages/SingleEdition/SingleEdition";
import { Analytics } from "@vercel/analytics/react"

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

function App() {
  return (
    <FilterProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/edition/:editionID"
            element={<SingleEdition />}
          />
        </Routes>
      </Router>
      <Analytics /> {/* Add the Analytics component here */}
    </FilterProvider>
  );
}

export default App;
