import React from "react";
import styles from "./PriceBox.module.css";

function PriceBox({price}) {
    
return (
    <div className={styles.price}>
           {formatPrice(price)}
    </div>
);
}

function formatPrice(price) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(price);
  }
  

export default PriceBox;
