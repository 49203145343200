import styles from "./SingleEdition.module.css";
import React, { useEffect } from "react";
import SalesChart from "../../page-components/SalesChart/SalesChart";
import { useFilters } from "../../context/filterContext";
import EditionHeader from "../../page-components/EditionHeader/EditionHeader";
import { useParams } from "react-router-dom";
import ListingGridActive from "../../page-components/ListingGridActive/ListingGridActive";
import PageContainer from "../../page-components/PageContainer/PageContainer";
import FiltersListings from "../../page-components/FiltersListings/FiltersListings";
function SingleEdition() {
  const { editionID } = useParams();
  const { state, dispatch } = useFilters();

  useEffect(() => {
    dispatch({ type: "SET_EDITION_ID", payload: editionID });
    console.log("check");
    console.log(editionID);
  }, [editionID, dispatch]);

  return (
    <PageContainer>
      <div className={styles.topSection}>
        <EditionHeader />
      </div>
      <div className={styles.chartSection}>
      <div className={styles.chartTitle}>Value History (Estimated with AI)</div>
      <FiltersListings />
      <SalesChart />
      </div>
      <div className={styles.listingsSection}>
      <div className={styles.listingsTitle}>Active Listings</div>
      <ListingGridActive />
      </div>
    </PageContainer>
  );
}

export default SingleEdition;
