import React from "react";
import styles from "./SignedBox.module.css";

function SignedBox({ signed }) {

    const signedDict = {
        "signed": "Signed",
        "unsigned": "Not Signed"
    };

    return (
        <div className={`${styles.signedBox} ${signed === 'signed' ? styles.signed : styles.unsigned}`}>
            {signedDict[signed]}
        </div>
    );
}

export default SignedBox;
