import React from "react";
import styles from "./ListingGrid.module.css";
import ImageCarousel from "../../bbourse-components/ImageCarousel/ImageCarousel";
import SignedBox from "../../bbourse-components/listing-box-components/SignedBox/SignedBox";
import PrintNumberBox from "../../bbourse-components/listing-box-components/PrintNumberBox/PrintNumberBox";
import TitleBox from "../../bbourse-components/listing-box-components/TitleBox/TitleBox";
import WebsiteBox from "../../bbourse-components/listing-box-components/WebsiteBox/WebsiteBox";
import useIsMobile from "../../hooks/useIsMobile"; // Assuming you place the hook in a hooks folder
import PriceBox from "../../bbourse-components/listing-box-components/PriceBox/PriceBox";
function ListingGrid({ listingData }) {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div className={styles.outerContainerMobile}>
        {listingData.map((item, index) => (
          <div key={index} className={styles.listingBoxMobile}>
            <ImageCarousel
              images={
                item.all_images !== null
                  ? item.all_images
                  : [item.gallery_url]
              }
            />
            <div className={styles.containerInfo}>
              <TitleBox title={item.title}></TitleBox>
              <SignedBox signed={item.is_signed}></SignedBox>
              <PrintNumberBox
                printNumber={item.print_number}
              ></PrintNumberBox>
             <PriceBox price={item.price}></PriceBox>
              <WebsiteBox link={item.view_item_url}></WebsiteBox>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.outerContainer}>
        <div className={styles.header}>
          <div className={styles.headerItem}>Image</div>
          <div className={styles.headerItem}>Title</div>
          <div className={styles.headerItem}>Signed</div>
          <div className={styles.headerItem}>Print Number</div>
          <div className={styles.headerItem}>Price</div>
          <div className={styles.headerItem}>Website</div>
        </div>
        <div className={styles.listingsContainer}>
        {listingData.map((item, index) => (
          <div key={index} className={styles.listingBox}>
            <div className={styles.imageColumn}>
              <ImageCarousel
                images={
                  item.all_images !== null
                    ? item.all_images
                    : [item.gallery_url]
                }
              />
            </div>
            <div>
              <TitleBox title={item.title}></TitleBox>
            </div>
            <div>
              <SignedBox signed={item.is_signed}></SignedBox>
            </div>
            <div>
              <PrintNumberBox
                printNumber={item.print_number}
              ></PrintNumberBox>
            </div>
            <div>
            <PriceBox price={item.price}></PriceBox>
            </div>
            <div>
              <WebsiteBox link={item.view_item_url}></WebsiteBox>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}


export default ListingGrid;
