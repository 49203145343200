import React from "react";
import styles from "./SideMenu.module.css";

function SideMenu({ items, selectedCategory, onCategoryChange }) {
  return (
    <div className={styles.sidebar}>
      {items.map((item) => (
        <button
          key={item.category}
          className={`${styles.sidebarItem} ${
            selectedCategory === item.category ? styles.active : ""
          }`}
          onClick={() => onCategoryChange(item.category)}
        >
            <div className={styles.containerIcon}>
          <span className="material-icons">{item.icon}</span>
          </div>
          <div className={styles.menuText}>{item.label}</div>
        </button>
      ))}
    </div>
  );
}

export default SideMenu;
