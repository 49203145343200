import React from 'react';
import styles from './Filter.module.css';

function Filter({ label, value, onChange, options }) {

  return (
    <div className={`${styles.filter}`}>
      <label className={styles.filterLabel}>{label}:</label>
      <select className={styles.filterSelect} value={value} onChange={onChange}>
        {options.map((option) => (
          <option className={styles.filterOption} key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}


export default Filter;
