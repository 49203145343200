import { useEffect, useState } from "react";
import axios from "axios";
import { useFilters } from "../context/filterContext";

function useDataWithFilters(apiUrl) { 
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { state } = useFilters();
  const { edition_id, print_number, signed, condition } = state;

  useEffect(() => {
    const fetchData = async () => {
      if (!edition_id) return; // Ensure edition_id is set

      setLoading(true);
      const filters = [];
      if (edition_id) filters.push(`edition_id=${edition_id}`);
      if (signed) filters.push(`signed=${encodeURIComponent(signed)}`);
      if (print_number) filters.push(`print_number=${print_number}`);
      if (condition) filters.push(`condition=${encodeURIComponent(condition)}`);
      const queryString = filters.length > 0 ? `${filters.join("&")}` : "";
      console.log(`${apiUrl}${queryString}`);
      
      try {
        const response = await axios.get(`${apiUrl}${queryString}`);
        setData(response.data);
        setError(null);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [edition_id, signed, print_number, condition, apiUrl]); 

  return { data, loading, error };
}

export default useDataWithFilters;
